<template>
  <AppPage :show-menu="false" class="centered">
    <div v-if="noAvailableMachines">
      <p>
        You've reached your maximum number of activated licenses.
      </p>
      <Button
        class="primary"
        :has-chevron="true"
        label="Manage Activated Licenses"
        :to="{name: 'AccountMachinesView'}"
      />
    </div>
    <div v-else-if="error">
      <h2>App could not launch.</h2>
      <p>
        We couldn't launch Storyfolder. Check your browser settings:
      </p>
      <ul v-if="$root.browser === 'chrome'">
        <li>Click on the 3-dot menu on the top right corner.</li>
        <li>Select "Settings".</li>
        <li>Scroll down and click on "Privacy and Security".</li>
        <li>Click on "Site Settings".</li>
        <li>Scroll down to "Pop-ups and redirects" and make sure the toggle is set to "Allowed".</li>
        <li>Under "Handlers", select "Allow sites to ask to become default handlers for protocols (recommended)".</li>
      </ul>

      <ul v-if="$root.browser === 'firefox'">
        <li>Click on the menu button (three lines) and choose "Options".</li>
        <li>Select "Privacy & Security" on the left side.</li>
        <li>Under the "Permissions" section, uncheck the box next to "Block pop-up windows" to disable the pop-up blocker.</li>
        <li>To enable app links, scroll to the "Applications" section and adjust the settings for the desired content type.</li>
      </ul>

      <ul v-if="$root.browser === 'safari'">
        <li>Go to "Safari" in the menu bar, then choose "Preferences".</li>
        <li>Click on "Websites" at the top, then select "Pop-up Windows" on the left side.</li>
        <li>Make sure the option "Block and Notify" or "Allow" is selected.</li>
        <li>For app links, go back to "Preferences" and select "General". Ensure that "Default web browser" is set to "Safari".</li>
      </ul>

      <ul v-if="$root.browser === 'edge'">
        <li>Click the 3-dot menu on the top right corner, then choose "Settings".</li>
        <li>Click on "Cookies and site permissions" on the left side.</li>
        <li>Click on "Pop-ups and redirects" and ensure the toggle is set to "Allowed".</li>
        <li>To enable app links, scroll down to "Handlers" and ensure the toggle is set to "Allowed".</li>
      </ul>
      <ul v-if="$root.browser === 'ie'">
        <li>Click on the gear icon in the top right corner, then select "Internet Options".</li>
        <li>Select the "Privacy" tab.</li>
        <li>Under the "Pop-up Blocker" section, uncheck the box that says "Turn on Pop-up Blocker".</li>
        <li>Click "OK" to save your changes.</li>
        <li>Unfortunately, Internet Explorer does not have a simple built-in way to handle custom protocol links. It may be necessary to use another browser or modify system settings.</li>
      </ul>

    </div>
    <div v-else-if="success">
      <h2>Success! App connected.</h2>
      <p>You may close this window or visit the <router-link to="/">home page</router-link>.</p>
    </div>
    <div v-else>
      <h2>Opening StoryFolder...</h2>
      <p v-if="machine">Machine ID: {{machine._id}}</p>
    </div>
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import AppPage from "@/components/organisms/AppPage.vue";
import Button from "@/components/atoms/Button.vue";

export default Vue.extend({
  components: {
    Button,
    AppPage
  },
  data(){
    return {
      noAvailableMachines: false,
      machine: null,
      error: false,
      success: false
    }
  },
  created(){
    this.setup();
  },
  methods: {
    async setup(){
      try{
        console.log('loading machines');
        await this.$store.dispatch('machines/load');
        if(this.$store.getters['machines/available']) {
          this.machine = await this.$store.dispatch('machines/create');
          window.location.href = 'storyfolder://auth-machine/' + this.machine._id;
          setTimeout(() => {
            this.refreshMachine(0)
          }, 3000);
        }else{
          this.noAvailableMachines = true;
        }
      }catch(e){
        console.error(e);
      }
    },
    async refreshMachine(count = 0){
      count = count || 0;
      // in here, we reload the machine every 5 seconds, up to 5 minutes, to see if the machine license was actually activated.
      const machine = await this.$store.dispatch('machines/read', this.machine._id);
      if(machine.activated){
        // redirect to some page
        this.success = true;
      }else{
        if(count <= 60){
          setTimeout(() => {
            count++
            this.refreshMachine(count);
          }, 3000);
        }else{
          this.error = true;
        }
      }
    }
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';
@import '../less/mixins';

.centered{
  text-align: center;
}
/deep/ .page-content{
  max-width: 30em;
  padding: 0 1em;
  margin: 0 auto;
}
@media(min-width: @fifthbreakpoint){
  .centered{
    font-size: 120%;
  }
}


</style>
